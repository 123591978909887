  <template>
  <section  id="Valores" class="moralValues" data-aos="fade-down">
    <div class="__content">
      <h2><b>{{ data.title }}</b></h2>
      <p>{{ data.description }}</p>
      <div class="__cards_container">
        <div class="shadow ___values_card" v-for="card in data.cards" :key="card.id">
          <img :src="require(`../../assets/${card.img}`)" alt="Imagen detectar (mobile)" loading="lazy"/>
          <h3>{{ card.title }}</h3>
          <div class="hoverLabel" :style="{backgroundColor: card.color}">
            <h3>{{ card.title }}</h3>
            <p>{{ card.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import store from '../../store';

export default {
  name: "Valores",
  data() {
    return {
      data: {
        title: 'Valores',
        description: 'Valores que se validan día a día a través de la acción y el comportamiento.',
        cards: [
          {
            id: 1,
            img: 'img/valores_img_honestidad.webp',
            title: 'Honestidad',
            description: 'Cuidamos nuestra hoja de vida y la del emprendedor.',
            color: '#1FAF4B',
          },
          {
            id: 2,
            img: 'img/valores_img_transparencia.webp',
            title: 'Transparencia',
            description: 'Decimos lo que pensamos.',
            color: '#33B0DE',
          },
          {
            id: 3,
            img: 'img/valores_img_coherencia.webp',
            title: 'Coherencia',
            description: 'Hacemos lo que decimos.',
            color: '#FAE53D',
          },
          {
            id: 4,
            img: 'img/valores_img_perseverancia.webp',
            title: 'Perseverancia',
            description: 'Apoyamos al emprendedor para que alcance sus sueños. Hacemos todo lo que está a nuestro alcance, en forma obsesiva, para que nuestros clientes realicen las acciones necesarias hasta lograr sus objetivos.',
            color: '#1FAF4B',
          },
          {
            id: 6,
            img: 'img/valores_img_compromiso.webp',
            title: 'Compromiso y Lealtad',
            description: 'Nos comprometemos con la empresa como si fuera nuestra.',
            color: '#33B0DE',
          },
          {
            id: 8,
            img: 'img/valores_img_agilidad.webp',
            title: 'Agilidad Reflexiva',
            description: 'Sin perder la necesaria agilidad en los tiempos actuales ayudamos a generar la reflexión para colaborar con los emprendedores a prever el impacto de sus decisiones.',
            color: '#FAE53D',
          }
        ]
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function() {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      if (store.state.sections.values) {
      this.data = {...store.state.sections.values.components};}
    }
  }
}
</script>

<style scoped lang="scss">

.moralValues {
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 50vh;
  width: 100%;

  .__content {
    max-width: 1366px;
    width: 100%;
    padding: 20px;
  }

  .__cards_container {
    display: grid;
    grid-gap: 25px 0;
    grid-template-columns: 1fr;
    grid-auto-rows: max(160px);
    width: 100%;

    .___values_card {
      align-items: center;
      border-radius: 18px;
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
    }

    .___values_card img {
      border-radius: 18px;
      position: absolute;
      max-height: 100%;
      max-width: 100%;
      z-index: -1;
    }

    .___values_card p {
      overflow: hidden;
    }

    .___values_card .hoverLabel {
      align-items: center;
      border-radius: 18px;
      color: white;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      width: 100%;
      position: absolute;
      padding: 20px;
      top: 0;
      transition: opacity 0.2s ease-out;
      opacity: 0;
      z-index: 1;
    }

    .___values_card .hoverLabel:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}

@media (min-width: 992px) {
  .moralValues {
    .__content {
      padding: 65px;
    }

    .__cards_container {
      grid-gap: 26px 23px;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: max(290px);
      margin: 100px 0 0;

      .___values_card {
      }

      .___values_card img {
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
</style>
